h2 {
    text-align: center;
    text-transform: uppercase;
    padding-top: 120px;

 }

 .aboutustext, .missiontext, .visiontext {
    font-size:18px;
    padding: 20px;
    line-height: 200%;
    text-align: justify;

 }
 .mainheader {
   
    margin: 0px 75px 75px 0px;
    width: 100%;
    padding: 0px;
 }
 .backgroundimg{
   width :100%;
   height: 100%;
   }
 .aboutusimg,.visionimg {
   width:500px;
    margin-left: -100px !important;
    padding: 25px;
 }
 
  @media only screen and (max-width: 768px) {
   /* For mobile phones:  */
   .aboutusimg, .visionimg {
      
      width: 400px;
        margin-left: -17px !important;
   }

} 
/*
@media only screen and (min-width: 768px) and (max-width:992px) {
   /* For tablets 
   .col-6, .col-8, .col-4 {

      width: 100%;
   }
   

}  */
