/* .products-tab ul>li>a {

    color: #1DA6A0;

} */

h5 {
    color : #1DA6A0;
    padding-top: 20px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {

    background-color: #1DA6A0;
    color: white;
}

.nav-link {
    color : #1DA6A0;
}


.transitions-container {
    display: flex;
    flex-direction: column;
    height: 150vh;
}

.content-wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
}
.productslist li {

    background-color: #1DA6A0;
    list-style: none;
    padding: 20px;
    text-align: justify;
    width: 300px;
    cursor: pointer;
    color: white;
}


 .productlist-active {
    
    background-color: #214948 !important;
 }

 .product-container {
     overflow-y: auto;
    padding: 20px;
}
.productsdisplay {
    padding: 30px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    z-index: 1;
}  



.product-item {
    padding: 15px;
    border-radius: 8px;
    background-color: #fff;
}


@media only screen and (max-width: 767px) {

    /* For mobile phones:  */

    .productslist li {

        width: 128px;
        display: flex;
        justify-content: center;
    }


}
@media only screen and (min-width: 768px) and (max-width:992px) {

    /* For tablets */
    .productslist li {

        width: 250px;
    }
}