.carousel-caption {

    color: black;
}
.carousel-indicators [data-bs-target] {

    background-color: black;
}

.carousel-control-prev-icon {

    color: black;
}

.d-block  {

    height: 700px;
    width : 200px
}


@media only screen and (max-width: 768px) {
    /* For mobile phones:  */
    
    .d-block  {

        height: 300px;
        width : 100px
    }

} 

@media only screen and (min-width: 768px) and (max-width:992px) {
    /* For tablets */
    
} 
