.footer {
    background-color: #1DA6A0;
    padding: 20px 0;
    margin-top: auto; /* This pushes the footer to the bottom */
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
}

.footer-img {
    max-width: 150px;
    background-color: white;
    height: auto;
}

@media (max-width: 768px) {
    .footer {
        padding: 20px 0;
    }
}

