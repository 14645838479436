.navbar {
    height: 140px;
    
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar>.container-fluid {

    background-color: white;

}

.Imglogo {
    width: 200px;
    margin-top: -7px;

}

.navbar-nav .nav-link {

    color: black;
    display: flex;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
    margin-right: 3rem;

}

.navbar-nav .nav-link:hover {

    color: #1DA6A0;
}

.navbar-nav .nav-link:focus-visible {

    background-color: #1DA6A0;
    color: white;
}


@media only screen and (max-width: 768px) {

    /* For mobile phones:  */
    .navbar-nav {
        /* height: 150px; */
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (min-width: 768px) and (max-width:992px) {

    /* For tablets */
    .navbar-nav {
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}